import React, { useState } from 'react'
import styled from '@emotion/native'
import { getDeck } from 'conkis-core/src/utils/cards'
import { px } from '~/utils/device'
import { translate } from '~/locale'
import { COLOR } from '~/const'
import Window from '~/components/stateless/Window'
import CardsDeck from '~/components/stateless/CardsDeck'
import CardsList from '~/components/stateless/CardsList'
import Deck from '~/components/stateless/Deck'
import Counter from '~/components/stateless/Counter'

export default function GamePlayerCards({ board, player_id, onCard, onClose }) {
    const player = board.state.players[player_id]
    const { cards, username, power } = player
    const [deck] = useState(
        getDeck(cards.filter((card) => card.used === undefined))
    )
    const list = cards
        .filter((card) => card.used !== undefined)
        .sort((a, b) => a.used - b.used)

    return (
        <Window
            width={'80%'}
            height={'85%'}
            gradient1={COLOR.BEIGE3}
            gradient2={COLOR.BEIGE2}
            onBackground={onClose}
            onClose={onClose}
        >
            <Content>
                <Top>
                    <PlayerInfo>
                        <PlayerInfoContainer>
                            <PlayerInfoLeft>
                                <PlayerUsername>{username}</PlayerUsername>
                            </PlayerInfoLeft>
                            <PlayerInfoRight>
                                <Counter
                                    value={power}
                                    icon={`icon-power.png`}
                                    scale={1}
                                    rounded={true}
                                    align="center"
                                />
                            </PlayerInfoRight>
                            {/* <PlayerIcon
                                source={UX_ASSETS[`icon-player-red.png`]}
                            /> */}
                        </PlayerInfoContainer>
                    </PlayerInfo>

                    {/* <H1 align="center" size={25}>
                        {translate('Available cards for ${username}', {
                            username,
                        })}
                    </H1> */}
                    <CardsDeck>
                        <Deck
                            cards={deck}
                            scale={0.7}
                            shadow={true}
                            onClick={onCard}
                        />
                    </CardsDeck>
                </Top>

                <Bottom>
                    <CardsList
                        list={list}
                        label={translate('Used Cards')}
                        scale={0.5}
                        onCard={onCard}
                        message={
                            list.length === 0
                                ? translate(
                                      '${username} has not used any cards yet ',
                                      {
                                          username,
                                      }
                                  )
                                : null
                        }
                    />
                </Bottom>
            </Content>
        </Window>
    )
}

const Content = styled.View`
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: ${px(50)};
`
const Top = styled.View`
    width: 85%;
`
const Bottom = styled.View`
    width: 85%;
    padding-top: ${px(100)};
`
const PlayerInfo = styled.View`
    justify-content: center;
    align-items: center;
    margin-bottom: ${px(50)};
`
const PlayerInfoContainer = styled.View`
    flex-direction: row;
`
const PlayerIcon = styled.Image`
    position: absolute;
    width: ${px(60)};
    height: ${px(60)};
    left: ${px(-30)};
    top: ${px(-5)};
`
const PlayerInfoLeft = styled.View`
    min-width: ${px(200)};
    height: ${px(50)};
    background: ${COLOR.BEIGE};
    border-radius: ${px(40)} 0 0 ${px(40)};
    justify-content: center;
    align-items: center;
    padding: 0 ${px(20)};
`
const PlayerInfoRight = styled.View`
    left: ${px(-5)};
`
const PlayerUsername = styled.Text`
    color: ${COLOR.BROWN};
    font-family: Poppins-Bold;
    font-size: ${px(25)};
`
