import React, { useState, useEffect } from 'react'
import styled from '@emotion/native'
import { COLOR, VIEW } from '~/const'
import { UX_ASSETS } from '~/const/assets'
import { setSounds, setMusic, changeView } from '~/store'
import { useGlobalState } from '~/store/hooks'
import { translate } from '~/locale'
import {
    px,
    isDesktop,
    isElectron,
    isFullScreen,
    toggleFullscreen,
} from '~/utils/device'
import { AUDIO } from '~/audio'
import Dialog from '~/components/stateless/Dialog'
import Button from '~/components/stateless/Button'
import Checkbox from '~/components/stateless/Checkbox'
import { FormField, FormFieldSeparator } from '~/components/stateless/Form'

export default function Settings({
    state,
    onResign,
    onReload,
    onClose,
    onBackground,
}) {
    const [{ sounds, music }, setGlobalState] = useGlobalState(
        'sounds',
        'music'
    )
    const [fullscreen, setFullScreen] = useState(isFullScreen())

    useEffect(() => {
        document.addEventListener(
            'fullscreenchange',
            updateStateFullscreenState,
            false
        )
        return () => {
            document.removeEventListener(
                'fullscreenchange',
                updateStateFullscreenState,
                false
            )
        }
    }, [])

    function onMusic() {
        AUDIO.CLICK()
        const value = !music
        setMusic(value)
        AUDIO.MUSIC(value)
    }

    function onSounds() {
        AUDIO.CLICK()
        const value = !sounds
        setSounds(value)
    }

    function updateStateFullscreenState() {
        setFullScreen(isFullScreen())
    }

    return (
        <Dialog
            width={850}
            height={800}
            onClose={onClose}
            onBackground={onBackground}
            background={UX_ASSETS['bg-pattern.png']}
        >
            <Container>
                <Content>
                    <FormField height={110}>
                        <Checkbox
                            label={translate('Music')}
                            checked={music}
                            onClick={onMusic}
                        />
                    </FormField>
                    <FormFieldSeparator />
                    <FormField height={110}>
                        <Checkbox
                            label={translate('Sounds')}
                            checked={sounds}
                            onClick={onSounds}
                        />
                    </FormField>
                    <FormFieldSeparator />
                    {isDesktop() && !isElectron() && (
                        <>
                            <FormField height={110}>
                                <Checkbox
                                    label={translate('Full Screen')}
                                    checked={fullscreen}
                                    onClick={toggleFullscreen}
                                />
                            </FormField>
                            <FormFieldSeparator />
                        </>
                    )}
                    <FormField>
                        <Button
                            width={'100%'}
                            fontSize={35}
                            label={translate('Reload Client')}
                            onClick={() => {
                                AUDIO.CLOSE()
                                onReload()
                            }}
                        />
                    </FormField>
                    <FormFieldSeparator />
                    {!state.spectator && (
                        <FormField>
                            <Button
                                width={'100%'}
                                fontSize={35}
                                color={COLOR.RED}
                                label={translate('Resign')}
                                onClick={() => {
                                    AUDIO.CLOSE()
                                    onResign()
                                }}
                            />
                        </FormField>
                    )}
                    {state.spectator && (
                        <FormField>
                            <Button
                                width={'100%'}
                                fontSize={35}
                                color={COLOR.BLUEDARK}
                                label={translate('Exit')}
                                onClick={() => {
                                    AUDIO.CLOSE()
                                    changeView(VIEW.HOME, { game_id: null })
                                }}
                            />
                        </FormField>
                    )}
                </Content>
            </Container>
        </Dialog>
    )
}

const Container = styled.View`
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
`

const Content = styled.View`
    width: 80%;
`
