import {
    Dimensions,
    Platform,
    PixelRatio,
    Linking,
    Keyboard,
} from 'react-native'
import { locale } from 'expo-localization'
import * as Device from 'expo-device'
import AsyncStorage from '@react-native-async-storage/async-storage'

const regexmobile =
    /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i

const PLATFORMS = {
    BROWSER: 'browser',
    ELECTRON: 'electron',
    IOS: 'ios',
    ANDROID: 'android',
    MOBILE: 'mobile',
}

export const pxRaw = (() => {
    const screenratio = Number(screenRatio().toFixed(2))
    // console.log(screenratio, getPixelRatio())
    return (number) => {
        return number * screenratio
        // return number / getPixelRatio()
    }
})()

export function px(number) {
    if (typeof number === 'string') return number
    const newnumber = pxRaw(number)
    return `${newnumber}px`
}

export function unpx(number) {
    if (number.includes('%')) return number
    return Number(number.slice(0, number.length - 2)) * getPixelRatio()
}

export function screenWidth() {
    return Dimensions.get('window').width
}

export function screenHeight() {
    return Dimensions.get('window').height
}

export function screenHeightWithKeyboard() {
    return Keyboard.isVisible !== undefined && Keyboard.isVisible()
        ? screenHeight() - Keyboard.metrics().height
        : screenHeight()
}

export function screenRatio() {
    return (screenWidth() + screenHeight()) / 3000
}

export function windowClose() {
    window.close()
}

export function changeRoute(route) {
    if (isWeb()) {
        window.history.pushState({}, '', route)
    }
}

export function isLandscape() {
    return screenHeight() > screenWidth()
}

export function getUserAgent() {
    return isWeb()
        ? navigator.userAgent
        : `${Device.brand} / ${Device.osName} ${Device.osVersion} / ${Device.modelName} / ${Device.modelId} / ${Device.deviceYearClass} / ${Device.deviceName}`
}

export function getPlatform() {
    return Platform.OS === 'web'
        ? isElectron()
            ? PLATFORMS.ELECTRON
            : regexmobile.test(navigator.userAgent)
            ? PLATFORMS.MOBILE
            : PLATFORMS.BROWSER
        : Platform.OS
}

export function isWeb() {
    return Platform.OS === 'web'
}

export function isAndroid() {
    return Platform.OS === PLATFORMS.ANDROID
}

export function isIos() {
    return Platform.OS === PLATFORMS.IOS
}

export function isNative() {
    return isAndroid() || isIos()
}

export function isElectron() {
    return window.isElectron === true
}

export function isDesktop() {
    const platform = getPlatform()
    return platform === 'browser' || platform === 'electron'
}

export function isTouchable() {
    return isNative() || (isWeb() && 'ontouchstart' in window)
}

export function getPixelRatio() {
    return PixelRatio.get()
}

export async function localStorageSet(key, value) {
    return await AsyncStorage.setItem(
        key,
        typeof value === 'string' ? value : JSON.stringify(value)
    )
}

export async function localStorageGet(key) {
    const value = await AsyncStorage.getItem(key)
    try {
        return JSON.parse(value)
    } catch (e) {
        // console.log(key, value, e)
    }
    return value
}

export async function localStorageRemove(key) {
    return await AsyncStorage.removeItem(key)
}

export function openUrl(url) {
    return Linking.openURL(url)
}

export function getLocale() {
    return 'en'
    const lang = locale.split('-')[0]
    return ['en', 'es'].includes(lang) ? lang : 'en'
}

export function isWindowActive() {
    // console.log('does this work in phone?s')
    return isWeb() ? !document.hidden : true
}

export function isFullScreen() {
    if (!document.fullscreenElement) {
        return false
    } else if (document.exitFullscreen) {
        return true
    }
}

export function toggleFullscreen() {
    isFullScreen()
        ? document.exitFullscreen()
        : document.documentElement.requestFullscreen()
}

export function hideKeyboard() {
    if (typeof Keyboard.isVisible === 'function' && Keyboard.isVisible()) {
        return Keyboard.dismiss()
    }
}

// console.log({
//     OS: Platform.OS,
//     isTouchable: isTouchable(),
//     getPixelRatio: getPixelRatio(),
//     screenRatio: screenRatio(),
//     screenWidth: screenWidth(),
//     screenHeight: screenHeight(),
//     // window,
//     // navigator,
// })
