import React, { useState, useEffect } from 'react'
import { TYPE } from 'dop'
import styled from '@emotion/native'
import QRCode from 'react-native-qrcode-svg'
import * as Clipboard from 'expo-clipboard'
import { DEPOSIT_STATUS } from 'conkis-core'
import { COINS, CHAINS, getShorterAddress } from '~/npm/web3'
import { COLOR } from '~/const'
import { UX_ASSETS } from '~/const/assets'
import { secondsToTime } from '~/utils'
import { px, pxRaw, openUrl } from '~/utils/device'
import { Server, serverNow } from '~/server'
import { openWeb3 } from '~/store'
import { useGlobalState, useLocalState, useTimer } from '~/store/hooks'
import { translate } from '~/locale'
import { AUDIO } from '~/audio'
import DialogShop from '~/components/stateless/DialogShop'
import Loading from '~/components/stateless/Loading'
import Button from '~/components/stateless/Button'
import DropDown from '~/components/stateless/DropDown'
import { FormField, FormFieldSeparator } from '~/components/stateless/Form'
import CopyValue from '~/components/stateless/CopyValue'
import FormValue from '~/components/stateless/FormValue'
import { Error, ErrorText } from '~/components/stateless/Error'

const METHODS = [
    { type: 'WALLET', label: 'Wallet / MetaMask' },
    { type: 'DEPOSIT', label: 'Deposit / Send Tx' },
]

export default function Payment({ item, onClose }) {
    const [{ user, web3 }, setGlobalState] = useGlobalState()
    const [state, setState] = useLocalState({
        step: 1,
        error: null,
        method_open: false,
        method_selected: null,
        coin_open: false,
        coin_selected: null,
    })
    const methods = METHODS.map((m) => translate(m.label))
    const coins = COINS.filter((c) => c.payable).map((c) => c.name)
    const coin = COINS[state.coin_selected]
    const chain_data = CHAINS[coin?.chain_id]

    useEffect(() => {
        const timeout = setInterval(async () => {
            if (state.step === 3) {
                const deposit = await Server.shopCheckDeposit({
                    deposit_id: state.deposit._id,
                })
                if (deposit?.status === DEPOSIT_STATUS.COMPLETED) {
                    AUDIO.SHOP()
                    setGlobalState({
                        user: { coins: user.coins + deposit.item.amount },
                    })
                    setState({ step: 4, deposit })
                }
            }
        }, 1000)
        return () => clearInterval(timeout)
    }, [state])

    async function onContinue() {
        AUDIO.CLICK()
        setState({ step: 2 })

        try {
            const deposit = await Server.shopCreateDeposit({
                shop_id: item.id,
                coin_id: coin.id,
            })
            const amount = deposit.amounts.find((a) => a.coin_id === coin.id)

            setState({
                step: 3,
                deposit,
                amount: amount.amount,
                price: amount.usd,
            })
        } catch (error) {
            setState({ step: 1, error })
        }
    }

    return (
        <DialogShop onClose={onClose}>
            <FormField>
                <FormValue
                    icon={UX_ASSETS['icon-dollar.png']}
                    value={`  ${item.usd}`}
                    align="flex-start"
                />
                <FormValue
                    icon={UX_ASSETS['icon-coin.png']}
                    value={`  x${item.amount}`}
                    width="50%"
                    align="flex-end"
                />
            </FormField>

            {state.step === 1 && (
                <>
                    <FormFieldSeparator />
                    <DropDown
                        placeholder={translate('Payment Method')}
                        open={state.method_open}
                        selected={state.method_selected}
                        options={methods}
                        width={'100%'}
                        height={85}
                        fontSize={35}
                        onOpen={(method_open) => {
                            AUDIO.CLICK()
                            setState({ method_open })
                        }}
                        onSelect={(method_selected) => {
                            setState({ method_selected })
                        }}
                    />
                    <FormFieldSeparator />
                    {state.method_selected !== null && (
                        <DropDown
                            placeholder={translate('Select Coin')}
                            open={state.coin_open}
                            selected={state.coin_selected}
                            options={coins}
                            width={'100%'}
                            height={85}
                            fontSize={35}
                            onOpen={(coin_open) => {
                                AUDIO.CLICK()
                                setState({ coin_open })
                            }}
                            onSelect={(coin_selected) => {
                                setState({ coin_selected })
                            }}
                        />
                    )}
                    {/* {state.coin_selected !== null &&
                        web3.addresses.length > 1 && (
                            <>
                                <FormFieldSeparator />
                                <DropDown
                                    placeholder={translate('Select Address')}
                                    // open={addresses_open}
                                    open={false}
                                    selected={web3.address}
                                    options={web3.addresses.map(
                                        getShorterAddress
                                    )}
                                    width={'100%'}
                                    height={85}
                                    fontSize={35}
                                    onOpen={(open) => {
                                        // setGlobalState({ web3: { error: null } })
                                        // setAddressesOpen(open)
                                    }}
                                    onSelect={(selected) => {
                                        AUDIO.CLICK()
                                        // onSelectAddress(addresses[selected])
                                    }}
                                />
                            </>
                        )} */}

                    {state.error === null && (
                        <Bottom>
                            {state.coin_selected !== null && (
                                <Button
                                    label={translate('Continue')}
                                    color={COLOR.BLUEDARK}
                                    width={'100%'}
                                    height={100}
                                    fontSize={35}
                                    onClick={onContinue}
                                />
                            )}
                        </Bottom>
                    )}

                    {state.error !== null && (
                        <Bottom>
                            <Error>
                                <ErrorText>
                                    {translate(
                                        'An error occurred during the purchase. Try again later or contact support.'
                                    )}
                                </ErrorText>
                                {/* <ErrorText bold={true}>
                                    {translate('Error: ${error}', {
                                        error: state.error,
                                    })}
                                </ErrorText> */}
                            </Error>
                        </Bottom>
                    )}
                </>
            )}

            {state.step === 2 && (
                <Content>
                    <Loading size={100} />
                </Content>
            )}

            {state.step === 3 && (
                <>
                    <FormFieldSeparator />
                    <FormField>
                        <FormValue
                            label={coin.symbol}
                            value={state.amount}
                            infolabel={coin.name}
                            infovalue={`1 ${coin.symbol} = $${state.price}`}
                        />
                    </FormField>
                    <FormFieldSeparator />

                    {METHODS[state.method_selected].type === 'WALLET' && (
                        <WalletPayment state={state} />
                    )}

                    {METHODS[state.method_selected].type === 'DEPOSIT' && (
                        <DepositPayment state={state} />
                    )}
                </>
            )}

            {state.step === 4 && (
                <>
                    <Completed>
                        <CompletedImage
                            source={UX_ASSETS['icon-check-green.png']}
                        />
                        <FormFieldSeparator />
                        <CompletedText>
                            {translate('Purchase completed')}!
                        </CompletedText>
                        <FormFieldSeparator />
                        <FormFieldSeparator />
                        <CompletedLink
                            onPress={() =>
                                openUrl(
                                    `${chain_data.blockExplorerUrls[0]}/tx/${state.deposit.tx.hash}`
                                )
                            }
                        >
                            <CompletedLinkText>
                                {translate('See tx on the block-explorer')}.
                            </CompletedLinkText>
                        </CompletedLink>
                    </Completed>
                    <Bottom>
                        <Button
                            label={translate('Continue')}
                            color={COLOR.BLUEDARK}
                            width={'100%'}
                            height={100}
                            fontSize={35}
                            onClick={onClose}
                        />
                    </Bottom>
                </>
            )}
        </DialogShop>
    )
}

function WalletPayment({ state }) {
    const [{ web3 }, setGlobalState] = useGlobalState(
        'web3.error',
        'web3.payment',
        'web3.payment.receipt'
    )
    const coin = COINS[state.coin_selected]
    const receipt = web3.payment?.receipt

    useEffect(() => {
        setGlobalState({
            web3: { payment: null },
        })
    }, [])

    useEffect(() => {
        if (receipt !== undefined) {
            // TO DO: send receipt to server
            setTimeout(() => {
                setGlobalState({
                    web3: { open: false },
                })
            }, 0)
        }
    }, [receipt])

    async function onPay() {
        openWeb3({
            coin,
            payment: TYPE.Replace({
                coin_id: coin.id,
                to: state.deposit.address,
                amount: state.amount,
                keep_open: false,
            }),
        })
    }

    return (
        <>
            {web3.address !== null && (
                <>
                    <FormField>
                        <FormValue
                            label={translate('From')}
                            value={getShorterAddress(web3.address)}
                        />
                    </FormField>
                    <FormFieldSeparator />
                </>
            )}

            <FormField>
                <FormValue
                    label={translate('To')}
                    value={getShorterAddress(state.deposit.address)}
                />
            </FormField>

            <FormFieldSeparator />

            <Bottom>
                {web3.payment !== null && web3.error === null ? (
                    <Loading size={100} />
                ) : (
                    <>
                        <Button
                            label={translate('Pay Now')}
                            color={COLOR.GREENDARK}
                            width={'100%'}
                            height={100}
                            fontSize={35}
                            onClick={onPay}
                        />

                        <FormFieldSeparator />

                        <Info>
                            <InfoSpin>
                                <Loading />
                            </InfoSpin>
                            <InfoText>
                                {translate(
                                    'This price for the payment will be available for'
                                )}
                                :
                            </InfoText>
                            <Timer
                                now={serverNow()}
                                expires={state.deposit.expires}
                            />
                        </Info>
                    </>
                )}
            </Bottom>
        </>
    )
}

function DepositPayment({ state }) {
    const coin = COINS[state.coin_selected]
    return (
        <>
            <FormField>
                <CopyValue value={state.deposit.address}>
                    <FormValue
                        value={getShorterAddress(state.deposit.address)}
                        infovalue={translate('Tap to copy')}
                        icon={UX_ASSETS['icon-copy.png']}
                        iconsize={35}
                    />
                </CopyValue>
            </FormField>

            <Bottom>
                <QRContainer>
                    <QRCode
                        value={state.deposit.address}
                        size={pxRaw(250)}
                        color={COLOR.BROWNBLACK}
                        backgroundColor={COLOR.BEIGE}
                    />
                </QRContainer>

                <Info>
                    <InfoSpin>
                        <Loading />
                    </InfoSpin>
                    <InfoText>
                        {translate(
                            'Send ${amount} ${symbol} to this address and wait for the confirmation',
                            {
                                amount: state.amount,
                                symbol: coin.symbol,
                            }
                        )}
                        .{/* <Dots /> */}
                    </InfoText>
                    <Timer now={serverNow()} expires={state.deposit.expires} />
                </Info>
            </Bottom>
        </>
    )
}

function Timer({ now, expires }) {
    const [ms] = useTimer({
        initial: expires - now,
        increment: -1000,
    })
    const seconds = Math.round(ms / 1000)

    return (
        <InfoTimer>
            <InfoTimerText>
                {secondsToTime(seconds < 0 ? 0 : seconds)}
            </InfoTimerText>
        </InfoTimer>
    )
}

const Content = styled.View`
    justify-content: center;
    align-items: center;
    flex: 1;
`

const Bottom = styled.View`
    flex: 1;
    justify-content: flex-end;
    align-items: center;
`

const QRContainer = styled.View`
    flex: 1;
    justify-content: center;
    align-items: center;
    // width: ${px(250)};
    // height: ${px(250)};
    // background: red;
`
const Info = styled.View`
    width: 100%;
    flex-direction: row;
    align-items: center;
`
const InfoSpin = styled.View`
    width: ${px(90)};
    height: 100%;
    align-items: center;
`
const InfoTimer = styled.View`
    width: ${px(90)};
    border-radius: ${px(30)};
    padding: ${px(5)} ${px(20)};
    background: ${COLOR.BROWN + '28'};
    justify-content: center;
    align-items: center;
    right: ${px(-5)};
`
const InfoTimerText = styled.Text`
    font-family: Poppins-Medium;
    color: ${COLOR.BROWN};
    font-size: ${px(18)};
`
const InfoText = styled.Text`
    flex: 1;
    font-family: Poppins-Medium;
    color: ${COLOR.BROWNDARK};
    font-size: ${px(16)};
    padding: 0 ${px(5)};
    text-align: center;
    opacity: 0.6;
`

const Completed = styled.View`
    width: 100%;
    height: ${px(500)};
    align-items: center;
    justify-content: center;
`
const CompletedImage = styled.Image`
    width: ${px(150)};
    height: ${px(150)};
`
const CompletedText = styled.Text`
    font-family: Poppins-Black;
    color: ${COLOR.BROWNDARK};
    font-size: ${px(32)};
`
const CompletedLink = styled.TouchableOpacity`
    border-bottom-width: ${px(3)};
    border-bottom-color: ${COLOR.GREEN};
`
const CompletedLinkText = styled.Text`
    font-family: Poppins-Medium;
    color: ${COLOR.GREENDARK};
    font-size: ${px(20)};
    border-bottom: ${px(2)} solid ${COLOR.GREENDARK};
`
