import React from 'react'
import styled from '@emotion/native'
import { px } from '~/utils/device'
import { CARD_ASSETS, UX_ASSETS } from '~/const/assets'
import { COLOR } from '~/const'
import { translate } from '~/locale'
import { cardToAsset } from '~/utils'
import Card from '~/components/stateless/Card'
import { H1 } from '~/components/stateless/Texts'

export default function CardsList({
    label,
    list,
    scale = 1,
    message = '',
    selecteds = [],
    onSelect,
    onCard,
}) {
    return (
        <>
            <H1 size={35}>{label}</H1>
            <CardsBlock>
                <CardsGrid>
                    {list.map((card, index) => {
                        const selected = selecteds
                            .map((c) => c._id)
                            .includes(card._id)
                        return (
                            <CardCell
                                key={index}
                                scale={scale}
                                last={(index + 1) % 6 === 0}
                            >
                                <Card
                                    power={card.power}
                                    source={CARD_ASSETS[cardToAsset(card)]}
                                    scale={scale * 0.7}
                                    onClick={(e) => {
                                        onCard(card, index, e)
                                    }}
                                />
                                {onSelect !== undefined && (
                                    <CardSelect
                                        selected={selected}
                                        scale={scale}
                                        onPress={() => {
                                            onSelect(card)
                                        }}
                                    >
                                        <CardSelectCheckbox
                                            scale={scale}
                                            source={
                                                UX_ASSETS[
                                                    selected
                                                        ? 'icon-check-on.png'
                                                        : 'icon-check-off.png'
                                                ]
                                            }
                                        ></CardSelectCheckbox>
                                        <CardSelectLabel
                                            scale={scale}
                                            selected={selected}
                                        >
                                            {translate('Mint')}
                                        </CardSelectLabel>
                                    </CardSelect>
                                )}
                            </CardCell>
                        )
                    })}
                </CardsGrid>
                {typeof message === 'string' && (
                    <MessageContainer>
                        <Message>{message}.</Message>
                    </MessageContainer>
                )}
            </CardsBlock>
        </>
    )
}

const CardsBlock = styled.View`
    padding: ${px(30)};
    border-radius: ${px(20)};
    background: ${COLOR.BEIGE + '55'};
`

const CardsGrid = styled.View`
    flex-direction: row;
    flex-wrap: wrap;
    // justify-content: space-between;
    // align-content: flex-start;
`
const CardCell = styled.View`
    padding-right: ${(p) => (p.last ? px(25 * p.scale) : px(25 * p.scale))};
    padding-bottom: ${(p) => px(25 * p.scale)};
    justify-content: center;
    align-items: center;
`

const CardSelect = styled.TouchableOpacity`
    margin-top: ${(p) => px(1 * p.scale)};
    width: 90%;
    height: ${(p) => px(35 * p.scale)};
    background: red;
    border-radius: ${px(35)};
    flex-direction: row;
    // border: 1px solid ${(p) => (p.selected ? 'transparent' : COLOR.BEIGE5)};
    background: ${(p) => (p.selected ? COLOR.BROWNDARK : COLOR.BROWN + '28')};
    justify-content: center;
    align-items: center;
`
const CardSelectCheckbox = styled.Image`
    width: ${(p) => px(18 * p.scale)};
    height: ${(p) => px(18 * p.scale)};
`
const CardSelectLabel = styled.Text`
    font-family: Poppins-Bold;
    font-size: ${(p) => px(16 * p.scale)};
    color: ${(p) => (p.selected ? 'white' : COLOR.BROWNDARK)};
    margin: 0 ${(p) => px(7 * p.scale)};
`

const MessageContainer = styled.View``

const Message = styled.Text`
    color: ${COLOR.BROWN};
    opacity: 0.4;
    text-align: center;
    font-family: Poppins-Medium;
    font-size: ${px(20)};
`
