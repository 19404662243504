import React from 'react'
import styled from '@emotion/native'
import { CARDS } from 'conkis-core'
import { CHAINS, CHAIN, getShorterAddress } from '~/npm/web3'
import { cardToAsset, getReadableTimestamp } from '~/utils'
import { px, openUrl } from '~/utils/device'
import { COLOR } from '~/const'
import { CARD_ASSETS } from '~/const/assets'
import Dialog from '~/components/stateless/Dialog'
import Card from '~/components/stateless/Card'
import { translate } from '~/locale'

const NFT_MANAGE_URL = {
    [CHAIN.BEAM]: (card) =>
        `https://sphere.market/account/${card.minted.address}?chain=beam`,
    [CHAIN.TELOS]: (card) =>
        `https://app.alturanft.com/user/${card.minted.address}?chain=telos`,
    // [CHAIN.TELOS]: () => 'https://wallet.telos.net/evm/collectible-inventory',
    // [CHAIN.TELOS_TESTNET]: () =>
    //     'https://wallet.telos.net/evm/collectible-inventory',
}

const ACQUISITION = {
    1: 'Reward',
    2: 'Shop',
    3: 'Give Away',
}

export default function CardInfo({ card, onClose }) {
    const { description, title } = CARDS[card.type]
    const show_data = card.hasOwnProperty('_id') && card.free === false

    return (
        <Dialog
            width={1050}
            height={show_data ? 750 : 550}
            onClose={onClose}
            onBackground={onClose}
        >
            <Container>
                <Content>
                    <Top>
                        <TopLeft>
                            <Card
                                scale={1.5}
                                counter={card.counter}
                                power={card.power}
                                rarity={card.rarity}
                                source={CARD_ASSETS[cardToAsset(card)]}
                            />
                        </TopLeft>
                        <TopRight>
                            <Name size={35} color={COLOR.BROWNDARK}>
                                {translate(title)}
                            </Name>
                            <Description>{translate(description)}</Description>
                        </TopRight>
                    </Top>
                    {show_data && <Data card={card} />}
                </Content>
            </Container>
        </Dialog>
    )
}

function Data({ card }) {
    const is_nft = card.hasOwnProperty('minted') && card.minted !== null
    const chain = is_nft ? CHAINS[card.minted.chain_id] : {}

    return (
        <>
            <DataContainer>
                <>
                    <DataField disabled={true}>
                        <DataLabel>ID:</DataLabel>
                        <DataValue>{card._id}</DataValue>
                    </DataField>
                    <DataField disabled={true}>
                        <DataLabel>{translate('Created')}:</DataLabel>
                        <DataValue>
                            {getReadableTimestamp(card.created)}
                        </DataValue>
                    </DataField>
                    <DataField disabled={true}>
                        <DataLabel>{translate('Acquisition')}:</DataLabel>
                        <DataValue>
                            {translate(ACQUISITION[card.acquisition])}
                        </DataValue>
                    </DataField>
                </>
                {is_nft && (
                    <>
                        <DataField disabled={true}>
                            <DataLabel>Blockchain:</DataLabel>
                            <DataValue>{chain.chainName}</DataValue>
                        </DataField>
                        <DataField
                            onPress={() =>
                                openUrl(
                                    `${chain.blockExplorerUrls[0]}/address/${card.minted.address}?tab=nfts`
                                )
                            }
                        >
                            <DataLabel>{translate('Address')}:</DataLabel>
                            <DataLink>{card.minted.address}</DataLink>
                        </DataField>

                        <DataField disabled={true}>
                            <DataLabel>{translate('Date of mint')}:</DataLabel>
                            <DataValue>
                                {getReadableTimestamp(card.minted.date)}
                            </DataValue>
                        </DataField>
                        <DataField
                            onPress={() =>
                                openUrl(
                                    `${chain.blockExplorerUrls[0]}/tx/${card.minted.hash}`
                                )
                            }
                        >
                            <DataLabel>Tx hash:</DataLabel>
                            <DataLink>{card.minted.hash}</DataLink>
                        </DataField>
                    </>
                )}
            </DataContainer>
            {is_nft && (
                <DataButton
                    onPress={() =>
                        openUrl(`${NFT_MANAGE_URL[card.minted.chain_id](card)}`)
                    }
                >
                    <DataButtonText>
                        {translate('Manage / Sell NFT')}
                    </DataButtonText>
                </DataButton>
            )}
        </>
    )
}

const Container = styled.View`
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
`

const Content = styled.View`
    width: 85%;
`
const Top = styled.View`
    flex-direction: row;
`
const TopLeft = styled.View`
    width: ${px(330)};
`
const TopRight = styled.View`
    margin-top: ${px(10)};
    flex: 1;
`

const Name = styled.Text`
    font-family: ChangaOne;
    font-size: ${px(55)};
    color: ${COLOR.BROWNDARK};
    // text-shadow: 0 ${px(3)} 0 rgba(0, 0, 0, 0.15);
`

const Description = styled.Text`
    margin-top: ${px(10)};
    font-size: ${px(30)};
    font-family: Poppins-SemiBold;
    color: ${COLOR.BROWN};
`

const DataContainer = styled.ScrollView`
    width: 100%;
    margin-top: ${px(20)};
    width: 100%;
    height: ${px(200)};
`
const DataField = styled.TouchableOpacity`
    margin: ${px(5)} 0;
    border-radius: ${px(10)};
    background: ${COLOR.BROWN + '28'};
    padding: ${px(5)};
    border-radius: ${px(25)};
    flex-direction: row;
    padding: 0 ${px(20)};
    height: ${px(40)};
    align-items: center;
`

const DataLabel = styled.Text`
    font-size: ${px(15)};
    font-family: Poppins-Black;
    color: ${COLOR.BROWNDARK};
    padding-right: ${px(10)};
`
const DataValue = styled.Text`
    font-size: ${px(15)};
    font-family: Poppins-SemiBold;
    color: ${COLOR.BROWNDARK};
`
const DataLink = styled.Text`
    font-size: ${px(15)};
    font-family: Poppins-SemiBold;
    color: ${COLOR.BLUE};
    border-bottom-width: ${px(2)};
    border-bottom-color: ${COLOR.BLUE};
`
const DataButton = styled.TouchableOpacity`
    height: ${px(50)};
    margin: ${px(5)} 0;
    border-radius: ${px(10)};
    background: ${COLOR.BROWNBLACK};
    border-radius: ${px(50)};
    align-items: center;
    justify-content: center;
`
const DataButtonText = styled.Text`
    font-size: ${px(18)};
    font-family: Poppins-Black;
    color: white;
    text-transform: uppercase;
`
