import React, { useEffect, useState } from 'react'
import { AppState } from 'react-native'
import styled from '@emotion/native'
import { randomInt } from 'conkis-core/src/utils'
import { REWARD_ASSET, USER_STATUS, PARTY_STATUS, TEAM } from 'conkis-core'
import { getRewardSearchQueue } from 'conkis-core/src/rewards'
import { secondsToTime } from '~/utils'
import { px } from '~/utils/device'
import { CONNECTION, COLOR, VIEW } from '~/const'
import { UX_ASSETS } from '~/const/assets'
import { translate } from '~/locale'
import { AUDIO } from '~/audio'
import { changeView } from '~/store'
import { useTimer } from '~/store/hooks'
import { useGlobalState, useLocalState } from '~/store/hooks'
import { Server, serverNow } from '~/server'
import Gif from '~/components/stateless/Gif'
import Counter from '~/components/stateless/Counter'
import Dialog from '~/components/stateless/Dialog'
import Button from '~/components/stateless/Button'
import Dots from '~/components/stateless/Dots'

const MIN_WAIT_TO_MATCH_BOT = 5000
const MAX_TIME_TO_ACCEPT = 20000
const MIN_TIME_TO_SHOW_OPTION_BOTS = 60000

export default function Searching({}) {
    const [{ connection, user }, setGlobalState] = useGlobalState(
        ({ path }) => {
            return state.update_global_state
                ? path[0] === 'connection' || path[0] === 'user'
                : false
        }
    )
    const [state, setState] = useLocalState({
        now: Date.now(),
        update_global_state: true,
    })
    const match_found =
        user.status?.type === USER_STATUS.PARTY &&
        user.status?.party?.status === PARTY_STATUS.MATCH_FOUND
    const only_bots = user.status.party?.visible === false

    useEffect(() => {
        if (
            user.status?.type === USER_STATUS.NONE ||
            connection !== CONNECTION.OPEN
        ) {
            changeView(VIEW.PLAY)
        }
    }, [user.status?.type, connection])

    function onAccept() {
        AUDIO.UNIT_SELECT()
        Server.partyReady({})
    }

    async function onPlayBot() {
        AUDIO.UNIT_SELECT()
        setState({ update_global_state: false })
        await Server.partySearch({ search: false })
        await Server.partyPlayBot({})
    }

    function onCancel() {
        AUDIO.CLOSE()
        Server.partyLeave()
        changeView(VIEW.PLAY)
    }

    return (
        <>
            <Container>
                <Background
                    imageStyle={{ resizeMode: 'stretch' }}
                    source={UX_ASSETS['bg-searching.png']}
                />
                <Content>
                    <Top>
                        <TitleContainer border={false} background={true}>
                            <Title>
                                {translate('Searching for players')}
                                <Dots />
                            </Title>
                        </TitleContainer>
                    </Top>
                    <Middle>
                        <Gif
                            width={500}
                            height={500}
                            frames={11}
                            source={UX_ASSETS['animation-knight.png']}
                        />
                    </Middle>
                    <Bottom>
                        <BottomSide>
                            <Counter
                                width={150}
                                value={user.trophies}
                                icon="icon-trophy.png"
                            />
                        </BottomSide>
                        <BottomCenter>
                            {!only_bots && (
                                <PlayBotOption
                                    state={state}
                                    onPlayBot={onPlayBot}
                                />
                            )}
                            <Cancel onPress={onCancel}>
                                <CancelBorder>
                                    <CancelText>
                                        {translate('Cancel')}
                                    </CancelText>
                                </CancelBorder>
                            </Cancel>
                        </BottomCenter>
                        <BottomSide align="flex-end">
                            {user.status.party?.search_start !== undefined && (
                                <TimerReward
                                    search_start={
                                        user.status.party.search_start
                                    }
                                    server_now={serverNow()}
                                />
                            )}
                        </BottomSide>
                    </Bottom>
                </Content>
                <Pattern
                    source={UX_ASSETS['bg-pattern.png']}
                    imageStyle={{ resizeMode: 'repeat' }}
                />
            </Container>

            {match_found && (
                <MatchFoundPlayer
                    user={user}
                    onAccept={onAccept}
                    onCancel={onCancel}
                />
            )}

            {only_bots && (
                <MatchFoundBot
                    user={user}
                    onAccept={onPlayBot}
                    onCancel={onCancel}
                />
            )}
        </>
    )
}

function PlayBotOption({ state, onPlayBot }) {
    const diff = Date.now() - state.now

    useTimer({ interval: 1000 })

    return diff > MIN_TIME_TO_SHOW_OPTION_BOTS ? (
        <PlayBotContainer>
            <PlayBotText>
                {translate('We are still searching, but you can choose to:')}
            </PlayBotText>
            <PlayBotButton onPress={onPlayBot}>
                <PlayBotButtonText>
                    {translate('Play vs Bot')}
                </PlayBotButtonText>
            </PlayBotButton>
        </PlayBotContainer>
    ) : null
}

function MatchFoundPlayer({ user, onAccept, onCancel }) {
    const { party, party_user_id } = user.status
    const size = party.game_size * 2
    const party_user = party[TEAM.TEAM_1].hasOwnProperty(party_user_id)
        ? party[TEAM.TEAM_1][party_user_id]
        : party[TEAM.TEAM_2][party_user_id]
    const ready = party_user.ready
    const users_ready = party.match_users_ready
    const seconds = Math.round((party.match_end - serverNow()) / 1000)

    useTimer({ interval: 100 })

    // useEffect(() => {
    //     if (seconds < 0) {
    //         onCancel()
    //     }
    // }, [seconds])

    return (
        <MatchFoundDialog
            seconds={seconds}
            ready={ready}
            size={size}
            users_ready={users_ready}
            message_penalty={user.cancels > 0 && !ready}
            onAccept={onAccept}
            onCancel={onCancel}
        />
    )
}

function MatchFoundBot({ user, onAccept, onCancel }) {
    const [state] = useLocalState({
        now: Date.now(),
        extra: randomInt(3000, 5000),
    })
    const minwait = user.wins * MIN_WAIT_TO_MATCH_BOT + state.extra
    const miliseconds = state.now + MAX_TIME_TO_ACCEPT - serverNow() + minwait
    const seconds = Math.round(miliseconds / 1000)
    const users_ready = MAX_TIME_TO_ACCEPT - miliseconds > 1500
    const show = Date.now() - state.now > minwait

    useTimer({ interval: 100 })

    useEffect(() => {
        if (seconds < 0) {
            onCancel()
        }
    }, [miliseconds])

    return show ? (
        <MatchFoundDialog
            seconds={seconds}
            ready={false}
            users_ready={users_ready}
            size={2}
            onAccept={onAccept}
            onCancel={onCancel}
        />
    ) : null
}

function MatchFoundDialog({
    ready,
    users_ready,
    size,
    seconds,
    message_penalty = false,
    onAccept,
    onCancel,
}) {
    const alert = !ready && seconds <= 5

    useEffect(() => {
        if (alert && seconds > 0) {
            AUDIO.TIMER()
        }
    }, [seconds])

    useEffect(() => {
        AUDIO.MATCH_FOUND()
    }, [])

    return (
        <Dialog
            width={700}
            height={400}
            borderColor={'transparent'}
            backgroundColor={COLOR.BLUEBLACK + '99'}
            background={UX_ASSETS['bg-pattern.png']}
            contentColor={COLOR.BEIGE2}
            contentColor2={COLOR.BEIGE4}
            onClose={onCancel}
        >
            <ContentMatchFound>
                <BlockMatchFound>
                    <TitleContainer border={true}>
                        <CounterMatchFound alert={alert}>
                            <CounterTextMatchFound>
                                {seconds}
                            </CounterTextMatchFound>
                        </CounterMatchFound>
                        <Title>{translate('Match Found')}</Title>
                    </TitleContainer>
                </BlockMatchFound>
                <BlockMatchFound>
                    <Players>
                        {Array(size)
                            .fill(null)
                            .map((n, index) => (
                                <Player
                                    key={index}
                                    source={
                                        UX_ASSETS[
                                            index < users_ready
                                                ? 'icon-user.png'
                                                : 'icon-user2.png'
                                        ]
                                    }
                                />
                            ))}
                    </Players>
                </BlockMatchFound>
                <BlockMatchFound>
                    {ready ? (
                        <MessageMatch>
                            {translate('Waiting for other players to accept')}.
                        </MessageMatch>
                    ) : (
                        <Button
                            width={'75%'}
                            height={80}
                            fontSize={35}
                            label={translate('Accept')}
                            onClick={onAccept}
                        />
                    )}
                </BlockMatchFound>
                {message_penalty && (
                    <MessageMatch color={COLOR.RED2} fontSize={px(16)}>
                        {translate(
                            'By canceling this match, you will receive a trophy penalty'
                        )}
                        .
                    </MessageMatch>
                )}
            </ContentMatchFound>
        </Dialog>
    )
}

function TimerReward({ server_now, search_start }) {
    const [ms] = useTimer({ initial: server_now - search_start })
    const seconds = Math.round(ms / 1000)
    const { amount, asset } = getRewardSearchQueue({
        seconds: Math.round((serverNow() - search_start) / 1000),
    })

    return (
        <TimerContainer>
            <TimerSubContainer>
                <TimerTop>
                    <TimeContainer>
                        <TimeReward>
                            <TimeRewardContainer>
                                <Counter
                                    width={160}
                                    value={`+ ${amount}`}
                                    icon={
                                        asset === REWARD_ASSET.COINS
                                            ? 'icon-coin.png'
                                            : 'icon-tulips.png'
                                    }
                                />
                            </TimeRewardContainer>
                        </TimeReward>
                        <Time>{secondsToTime(seconds)}</Time>
                    </TimeContainer>
                </TimerTop>
                <TimerBottom>
                    {translate('Earn rewards by waiting in the queue')}.
                </TimerBottom>
            </TimerSubContainer>
        </TimerContainer>
    )
}

const Container = styled.View`
    width: 100%;
    height: 100%;
    position: absolute;
    background: ${COLOR.BEIGE2};
`

const Background = styled.ImageBackground`
    width: 100%;
    height: 100%;
`

const Content = styled.ImageBackground`
    position: absolute;
    width: 100%;
    height: 100%;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
`

const Pattern = styled.ImageBackground`
    width: 100%;
    height: 100%;
    position: absolute;
    pointer-events: none;
`

const Top = styled.View`
    width: ${px(600)};
    padding: ${px(25)} 0;
`
const Middle = styled.View``
const Bottom = styled.View`
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`

const BottomSide = styled.View`
    width: 30%;
    justify-content: center;
    align-items: ${(p) => p.align || 'normal'};
    padding: 0 ${px(100)};
`

const BottomCenter = styled.View`
    width: 40%;
    height: ${px(180)};
    justify-content: center;
    align-items: center;
`

const PlayBotContainer = styled.View`
    height: ${px(50)};
    border-radius: ${px(50)};
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: ${px(3)} solid ${COLOR.BROWN + '44'};
    margin-bottom: ${px(40)};
`

const PlayBotText = styled.Text`
    font-family: Poppins-Medium;
    color: ${COLOR.BROWN};
    font-size: ${px(18)};
    padding: 0 ${px(20)};
`

const PlayBotButton = styled.TouchableOpacity`
    background: ${COLOR.BROWNDARK};
    padding: 0 ${px(40)};
    height: 100%;
    border-radius: ${px(50)};
    justify-content: center;
    align-items: center;
`
const PlayBotButtonText = styled.Text`
    font-family: Poppins-Bold;
    color: ${COLOR.BEIGE};
    font-size: ${px(20)};
`

const Cancel = styled.TouchableOpacity`
    padding-bottom: ${px(20)};
`

const CancelBorder = styled.View`
    border-bottom-width: ${px(3)};
    border-bottom-color: ${COLOR.BROWN};
`

const CancelText = styled.Text`
    font-family: Poppins-Medium;
    color: ${COLOR.BROWN};
    font-size: ${px(55)};
    line-height: ${px(60)};
    padding-bottom: ${px(5)};
`

const TitleContainer = styled.View`
    border-radius: ${px(30)};
    width: 100%;
    height: ${px(60)};
    background: ${COLOR.BROWN + '28'};
    justify-content: center;
    align-items: center;
`

const Title = styled.Text`
    font-family: Poppins-Black;
    color: ${COLOR.BROWN};
    font-size: ${px(25)};
`

const TimerContainer = styled.View`
    align-items: flex-end;
`
const TimerSubContainer = styled.View``

const TimerTop = styled.View`
    align-items: center;
    padding-left: ${px(10)};
`
const TimerBottom = styled.Text`
    font-family: Poppins-Medium;
    color: ${COLOR.BROWN};
    font-size: ${px(18)};
    padding-top: ${px(20)};
    text-align: center;
`

const TimeReward = styled.View`
    position: absolute;
    width: 100%;
    height: 100%;
    // left: 0;
`

const TimeRewardContainer = styled.View``

const TimeContainer = styled.View`
    height: ${px(50)};
    border-radius: ${px(50)};
    background: ${COLOR.BEIGE2};
    align-items: flex-end;
    justify-content: center;
    width: ${px(300)};
`
const Time = styled.Text`
    padding-right: ${px(30)};
    color: ${COLOR.BROWNDARK};
    font-size: ${px(28)};
    font-family: Poppins-Bold;
`

// MatchFound

const ContentMatchFound = styled.View`
    width: 100%;
    height: 100%;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    padding: ${px(20)};
`

const BlockMatchFound = styled.View`
    width: 100%;
    min-height: ${px(85)};
    justify-content: center;
    align-items: center;
`

const CounterMatchFound = styled.View`
    width: ${px(50)};
    height: ${px(50)};
    left: ${px(5)};
    border-radius: 50%;
    position: absolute;
    background: ${(p) => (p.alert ? COLOR.RED : COLOR.BROWNDARK + '55')};
    align-items: center;
    justify-content: center;
`

const CounterTextMatchFound = styled.Text`
    color: white;
    font-size: ${px(22)};
    font-family: Poppins-Bold;
`
const Players = styled.View`
    width: 100%;
    flex-direction: row;
    justify-content: center;
`

const Player = styled.Image`
    width: ${px(100)};
    height: ${px(100)};
`

const MessageMatch = styled.Text`
    color: ${(p) => p.color || COLOR.BEIGE5};
    font-size: ${(p) => p.fontSize || px(22)};
    font-family: Poppins-Medium;
    width: 100%;
    text-align: center;
`
